<template>
  <div class="app-container-empty">
    <el-row class="orderTop">
      <el-col :style="{ width: 'calc(33% - 7px)' }">
        <el-card class="box-card orderTop">
          <div slot="header" class="cardHeader">
            <span class="card-title">{{
              $t("order.statistics.orderStatistics")
            }}</span>
            <el-radio-group
              v-model="topLeftOrder"
              @change="getOrderStatistics(topLeftOrder)"
              style="float: right"
            >
              <el-radio :label="7">7{{ $t("commons.day") }}</el-radio>
              <el-radio :label="30">30{{ $t("commons.day") }}</el-radio>
              <el-radio :label="90">90{{ $t("commons.day") }}</el-radio>
            </el-radio-group>
          </div>
          <div v-loading="orderStatisticsLoading">
            <ul class="orderUl">
              <li>
                <span class="label">
                  {{ $t("order.statistics.reportsNumber") }}
                </span>
                <span class="num">
                  {{ reportsNumber }}
                </span>
              </li>
              <li>
                <span class="label">
                  {{ $t("order.statistics.completeNumber") }}
                </span>
                <span class="num complete">
                  {{ completeNumber }}
                </span>
              </li>
              <li>
                <span class="label">
                  {{ $t("order.statistics.receiveOvertimeNumber") }}
                </span>
                <span class="num overtime">
                  {{ receiveOvertimeNumber }}
                </span>
              </li>
              <li>
                <span class="label">
                  {{ $t("order.statistics.implementOvertimeNumber") }}
                </span>
                <span class="num overtime">
                  {{ implementOvertimeNumber }}
                </span>
              </li>
              <li>
                <span class="label">
                  {{ $t("order.statistics.completeOvertimeNumber") }}
                </span>
                <span class="num overtime">
                  {{ completeOvertimeNumber }}
                </span>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :style="{ width: 'calc(34% - 6px)', margin: '0 10px' }">
        <el-card class="box-card orderTop">
          <div slot="header" class="cardHeader">
            <span class="card-title">{{
              $t("order.statistics.reportOrderStatistics")
            }}</span>
            <el-radio-group
              v-model="topCenterOrder"
              @change="getDeviceReportsList(topCenterOrder)"
              style="float: right"
            >
              <el-radio :label="7">7{{ $t("commons.day") }}</el-radio>
              <el-radio :label="30">30{{ $t("commons.day") }}</el-radio>
              <el-radio :label="90">90{{ $t("commons.day") }}</el-radio>
            </el-radio-group>
          </div>
          <div>
            <v-chart
              style="width: 100%"
              class="chartTop"
              :options="topCenterOrderOptions"
              v-loading="topCenterOrderOptionsLoading"
              :auto-resize="true"
            ></v-chart>
          </div>
        </el-card>
      </el-col>
      <el-col :style="{ width: 'calc(33% - 7px)' }">
        <el-card class="box-card orderTop">
          <div slot="header" class="cardHeader">
            <span class="card-title">{{
              $t("order.statistics.completeOrderStatistics")
            }}</span>
            <el-radio-group
              v-model="downLeftOrder"
              @change="getDeviceCompleteList(downLeftOrder)"
              style="float: right"
            >
              <el-radio :label="7">7{{ $t("commons.day") }}</el-radio>
              <el-radio :label="30">30{{ $t("commons.day") }}</el-radio>
              <el-radio :label="90">90{{ $t("commons.day") }}</el-radio>
            </el-radio-group>
          </div>
          <div>
            <v-chart
              style="width: 100%"
              class="chartTop"
              :options="downLeftOrderOptions"
              v-loading="downLeftOrderOptionsLoading"
              :auto-resize="true"
            ></v-chart>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row class="orderDown">
      <el-col :style="{ width: 'calc(50% - 5px)' }">
        <el-card class="box-card orderDown">
          <div slot="header" class="cardHeader">
            <span class="card-title">{{
              $t("order.statistics.reportOrderTrend")
            }}</span>
            <el-radio-group
              v-model="topRightOrder"
              @change="getReportsList(topRightOrder)"
              style="float: right"
            >
              <el-radio :label="7">7{{ $t("commons.day") }}</el-radio>
              <el-radio :label="30">30{{ $t("commons.day") }}</el-radio>
              <el-radio :label="90">90{{ $t("commons.day") }}</el-radio>
            </el-radio-group>
          </div>
          <div>
            <v-chart
              style="width: 100%"
              class="chartTop"
              :options="topRightOrderOptions"
              v-loading="topRightOrderOptionsLoading"
              :auto-resize="true"
            ></v-chart>
          </div>
        </el-card>
      </el-col>
      <el-col :style="{ width: 'calc(50% - 5px)', marginLeft: '10px' }">
        <el-card class="box-card orderDown">
          <div slot="header" class="cardHeader">
            <span class="card-title">{{
              $t("order.statistics.completeOrderTrend")
            }}</span>
            <el-radio-group
              v-model="downRightOrder"
              @change="getCompleteList(downRightOrder)"
              style="float: right"
            >
              <el-radio :label="7">7{{ $t("commons.day") }}</el-radio>
              <el-radio :label="30">30{{ $t("commons.day") }}</el-radio>
              <el-radio :label="90">90{{ $t("commons.day") }}</el-radio>
            </el-radio-group>
          </div>
          <div>
            <v-chart
              style="width: 100%"
              class="chartTop"
              :options="downRightOrderOptions"
              v-loading="downRightOrderOptionsLoading"
              :auto-resize="true"
            ></v-chart>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 导入主题，引用时在v-chart中增加theme="macarons"
import "echarts/theme/macarons";
import ECharts from "vue-echarts";
import {
  getOrderStatistics,
  getDeviceReportsList,
  getReportsList,
  getDeviceCompleteList,
  getCompleteList,
} from "@/api/business/order/tenant/orderStatistics";
import moment from "moment";

export default {
  name: "OrderStatistics",
  data() {
    return {
      reportsNumber: 0,
      completeNumber: 0,
      receiveOvertimeNumber: 0,
      implementOvertimeNumber: 0,
      completeOvertimeNumber: 0,
      orderStatisticsLoading: false,
      topLeftOrder: 7,
      topCenterOrder: 7,
      topRightOrder: 7,
      downLeftOrder: 7,
      downRightOrder: 7,
      topCenterOrderOptions: {},
      topCenterOrderOptionsLoading: false,
      topRightOrderOptions: {},
      topRightOrderOptionsLoading: false,
      downLeftOrderOptions: {},
      downLeftOrderOptionsLoading: false,
      downRightOrderOptions: {},
      downRightOrderOptionsLoading: false,
    };
  },
  created() {
    this.getOrderStatistics(7);
    this.getDeviceReportsList(7);
    this.getReportsList(7);
    this.getDeviceCompleteList(7);
    this.getCompleteList(7);
  },
  methods: {
    getOrderStatistics(day) {
      this.orderStatisticsLoading = true;
      getOrderStatistics({ day: day })
        .then((data) => {
          this.reportsNumber = data.reportsNumber;
          this.completeNumber = data.completeNumber;
          this.receiveOvertimeNumber = data.receiveOvertimeNumber;
          this.implementOvertimeNumber = data.implementOvertimeNumber;
          this.completeOvertimeNumber = data.completeOvertimeNumber;
        })
        .finally(() => {
          this.orderStatisticsLoading = false;
        });
    },
    getDeviceReportsList(day) {
      this.topCenterOrderOptionsLoading = true;
      getDeviceReportsList({ day: day })
        .then((data) => {
          let dataX = [];
          let dataY = [];
          data.map((v) => {
            dataX.push(v.classifyName);
            dataY.push(v.number);
          });
          this.topCenterOrderOptions = this.buildPillarOptions(dataX, dataY);
        })
        .finally(() => {
          this.topCenterOrderOptionsLoading = false;
        });
    },
    getReportsList(day) {
      this.topRightOrderOptionsLoading = true;
      getReportsList({ day: day })
        .then((data) => {
          let dataX = [];
          let dataY = [];
          data.map((v) => {
            dataX.push(moment(v.recordDate).format("MM-DD"));
            dataY.push(v.statisticsNumber);
          });
          this.topRightOrderOptions = this.buildLineOptions(dataX, dataY);
        })
        .finally(() => {
          this.topRightOrderOptionsLoading = false;
        });
    },
    getDeviceCompleteList(day) {
      this.downLeftOrderOptionsLoading = true;
      getDeviceCompleteList({ day: day })
        .then((data) => {
          let dataX = [];
          let dataY = [];
          data.map((v) => {
            dataX.push(v.classifyName);
            dataY.push(v.number);
          });
          this.downLeftOrderOptions = this.buildPillarOptions(dataX, dataY);
        })
        .finally(() => {
          this.downLeftOrderOptionsLoading = false;
        });
    },
    getCompleteList(day) {
      this.downRightOrderOptionsLoading = true;
      getCompleteList({ day: day })
        .then((data) => {
          let dataX = [];
          let dataY = [];
          data.map((v) => {
            dataX.push(moment(v.recordDate).format("MM-DD"));
            dataY.push(v.statisticsNumber);
          });
          this.downRightOrderOptions = this.buildLineOptions(dataX, dataY);
        })
        .finally(() => {
          this.downRightOrderOptionsLoading = false;
        });
    },

    // 构建拆线图的配置
    buildLineOptions(dataX, dataY) {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
            lineStyle: {
              color: "#4A92FF",
              opacity: 0.5,
              width: 1,
            },
          },
          backgroundColor: "#418CFD",
          extraCssText: "opacity: 0.8;",
          padding: [5, 10],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          data: dataX,
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#A5A5A5",
              fontSize: 14,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#EEEEEE",
            },
          },
        },
        yAxis: {
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "#EEEEEE",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#A5A5A5",
              fontSize: 14,
            },
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            name: this.$t("facilityHome.number"),
            itemStyle: {
              normal: {
                color: "#4A92FF",
                opacity: 0.5,
              },
            },
            lineStyle: {
              opacity: 0.5,
            },
            areaStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#4A92FF",
                },
                {
                  offset: 1,
                  color: "#ffe",
                },
              ]),
            },
            smooth: true,
            symbol: "circle",
            symbolSize: 5,
            showSymbol: false,
            type: "line",
            data: dataY,
            animationDuration: 2800,
            animationEasing: "cubicInOut",
          },
        ],
      };
    },
    // 构建柱状图的配置
    buildPillarOptions(dataX, dataY) {
      return {
        itemStyle: {
          barBorderRadius: [5, 5, 0, 0],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            shadowStyle: {
              color: "#F3F7FF",
              opacity: 0,
            },
          },
          // backgroundColor: '#418CFD',
          // extraCssText: 'opacity: 0.8;'
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: dataX,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              textStyle: {
                color: "#A5A5A5",
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                color: "#EEEEEE",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "#EEEEEE",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#A5A5A5",
                fontSize: 14,
              },
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: this.$t("facilityHome.number"),
            type: "bar",
            itemStyle: {
              color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#B5DBFF" },
                { offset: 0.5, color: "#4497FF" },
                { offset: 1, color: "#4497FF" },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new ECharts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#4497FF" },
                  { offset: 0.7, color: "#4497FF" },
                  { offset: 1, color: "#B5DBFF" },
                ]),
              },
            },
            stack: "vistors",
            color: "#5d9cec",
            barWidth: "60%",
            data: dataY,
            animationDuration: this.animationDuration,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.orderTop {
  min-height: calc(50vh - 60px);
}
.chartTop {
  height: calc(50vh - 150px);
  min-height: 320px;
}
.orderDown {
  min-height: calc(50vh - 60px);
  margin-top: 12px;
}
.cardHeader {
  min-height: 10px;
  max-height: 10px;
}
.card-title {
  font-size: 16px;
  color: rgb(153, 153, 153);
  font-weight: bold;
}
.orderUl {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.orderUl li {
  padding: 20px 5px;
  height: 64px;
}
.orderUl li .label {
  /*font-size: 16px;*/
  /*font-weight: 500;*/
  /*color: #303133;*/
}
.orderUl li .num {
  float: right;
  font-weight: bold;
  font-size: 20px;
  color: #909399;
}
.orderUl li .complete {
  color: #67c23a;
}
.orderUl li .overtime {
  color: #e6a23c;
}
</style>
