import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 查询工单统计
 * @param params
 */
export function getOrderStatistics(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/statistics?day='+params.day,
        method: 'get',
    })
}

/**
 * 查询填报工单统计
 * @param params
 */
export function getDeviceReportsList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/deviceReportsList?day='+params.day,
        method: 'get',
    })
}

/**
 * 查询填报工单趋势
 * @param params
 */
export function getReportsList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/reportsList?day='+params.day,
        method: 'get',
    })
}

/**
 * 查询完成工单统计
 * @param params
 */
export function getDeviceCompleteList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/deviceCompleteList?day='+params.day,
        method: 'get',
    })
}

/**
 * 查询完成工单趋势
 * @param params
 */
export function getCompleteList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/completeList?day='+params.day,
        method: 'get',
    })
}
